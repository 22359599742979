import styled from 'styled-components';
import cataliseVideo from '../../Assets/Low Catalise Moviment.mp4';
import cataliseLogo from '../../Assets/logo.svg';
import AboutButton from '../../Components/aboutButton.js';
import { BsArrowDownShort } from 'react-icons/bs';
import { useRef } from 'react';
import { forwardRef, useImperativeHandle } from "react"

const BackgroundVideo = styled.video`
  position: absolute;
  right: 0;
  bottom: 0;
  top:0;
  width: 100%;
  height: 100%;
  background-color: black; /* in case the video doesn't fit the whole page*/
  object-fit: cover; /*cover video background */
  z-index:99;

  ::-webkit-media-controls {
    display:none !important;
  }
`;
const TextSlide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  h1 {
    margin-bottom: 50px;
    text-align: center;
    font-size: 100px;
    font-weight: 600;
  }
  p {
    text-align: justify;
    width: 50%;
    font-size: 24px;
    line-height: 40px;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 50px;
    }
    p {
      width: 70%;
      font-size: 15px;
      line-height: 30px;
    }
  }
`;
const Logo = styled.img`
  max-width: 800px;
  width: 80%;
  opacity: 0;
  transform: scale(1);
  transition: opacity ease-in-out 2s, transform ease-out 1s;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

const LogoPage = forwardRef((props, ref) => {
  
    useImperativeHandle(ref, () => ({
      onEnterSlide() {
        ImgLogoRef.current.style.opacity = 1;
        ImgLogoRef.current.style.transform = "scale(1)";
      },
      onExitSlide() {
        ImgLogoRef.current.style.opacity = 0;
        ImgLogoRef.current.style.transform ="scale(1.3)";
      }
    })) 

    const ImgLogoRef = useRef();

    return <>
        <BackgroundVideo loop muted autoPlay src={cataliseVideo}/>
        <TextSlide>
            <Logo src={cataliseLogo} alt="Logo" ref={ImgLogoRef}/>
            <AboutButton onClick={() => props.scrollToSlide(1)}>
                Catalise XT
                <BsArrowDownShort size={25}/>
            </AboutButton>
        </TextSlide>
    </>
});
export default LogoPage;