import * as React from 'react';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useRef } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import brLocale from 'date-fns/locale/pt-BR';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import MainSlide from '../../Components/mainSlide';
import { forwardRef, useImperativeHandle } from "react"

import cataliseSchoolLogo from '../../Assets/logoSchool.svg';
import marcelo from '../../Assets/marcelo.jpg';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask="(#0) 00000-0000"
        definitions={{
            '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

TextMaskCustom.propTypes = {
name: PropTypes.string.isRequired,
onChange: PropTypes.func.isRequired,
};

const FormSlide = styled(MainSlide)`
background-image: url(${marcelo});
`
const SendForm = styled.form`
display: flex;
flex-direction: column;
max-width: 400px;
width: 80%;
div {
    margin-bottom: 10px;
}
button {
    padding: 15px;
}
p {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: #fff;
    text-align: center;
}
a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #819ba6;
    margin-top: 8px;
    svg {
    margin-left: 5px;
    }
}
`;
const StyledReCAPTCHA = styled(ReCAPTCHA)`
width: 100%;
display: flex;
justify-content: center;
    height: 90px;
div {
}
`;
const Logo = styled.img`
  max-width: 800px;
  width: 80%;
  opacity: 0;
  transform: scale(1);
  transition: opacity ease-in-out 2s, transform ease-out 1s;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

const FormPage = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        onEnterSlide() {
            ImgLogoSchoolRef.current.style.opacity = 1;
            ImgLogoSchoolRef.current.style.transform = "";
        },
        onExitSlide() {
            ImgLogoSchoolRef.current.style.opacity = 0;
            ImgLogoSchoolRef.current.style.transform ="translateY(-15px)";
        }
    }))

    const [values, setValues] = React.useState({
      textmask: ''
    });
    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    };
    const [selectedDate, setSelectedDate] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(0);
    const [recaptcha, setRecaptcha] = useState(null);
    const ImgLogoSchoolRef = useRef();
  
    const NameInput = useRef();
    const EmailInput = useRef();
    
    const handleSend = () => {
        const re = /\S+@\S+\.\S+/;
        const name = NameInput.current.value;
        const email = EmailInput.current.value;
        const phone = values.textmask;
        if (name.length < 3)
        {
        setError("Digite um nome");
        return;
        }
        if (!re.test(email))
        {
        setError("E-mail inválido");
        return;
        }
        if (phone.length !== 15)
        {
        setError("WhatsApp inválido, deve ter 15 dígitos");
        return;
        }
        if (!selectedDate)
        {
        setError("Selecione uma data");
        return;
        }
        const date = selectedDate.toLocaleDateString("pt-BR");
        if (date === "Invalid Date")
        {
        setError("Data inválida");
        return;
        }
        if (!recaptcha)
        {
        setError("Resolva o captcha");
        return;
        }
        setError(null);

        let formData = new FormData();
        formData.append('nome', name);
        formData.append('email', email);
        formData.append('whatsapp', phone);
        formData.append('date', date);
        formData.append('captcha', recaptcha);

        setStatus(1);

        fetch("/send.php",
        {
            body: formData,
            method: "post"
        }).then((res) => {
        if (res.ok)
        {
            setStatus(2);
        }
        else
        {
            setError(res.statusText)
            setStatus(0);
        }
        });
    }

    const renderSendButton = () => {
        switch(status)
        {
        case 1: //sending
            return <LoadingButton
            loading
            loadingPosition="start"
            variant="outlined"
            >
            Enviando
            </LoadingButton>;
        case 2: //sended
            return <Alert color="success">Enviado</Alert>;
        default: //normal
            return <Button onClick={handleSend} variant="contained">Enviar</Button>;
        }
    };

    return <FormSlide>
    <Logo src={cataliseSchoolLogo} alt="Logo" ref={ImgLogoSchoolRef}/>
    <SendForm>
      <p>Inscrições processo seletivo</p>
      <TextField inputRef={NameInput} label="Nome" variant="outlined" />
      <TextField inputRef={EmailInput} label="E-mail" variant="outlined" />
      <FormControl variant="outlined">
        <InputLabel htmlFor="formatted-text-mask-input">WhatsApp</InputLabel>
        <OutlinedInput
          label="WhatsApp"
          value={values.textmask}
          onChange={handleChange}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
        />
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
        <DatePicker
          label="Data de Nascimento"
          variant="outlined"
          value={selectedDate}
          onChange={(newValue) => {
            setSelectedDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <StyledReCAPTCHA
        sitekey="6LeAMLQeAAAAABsN9gMymv59aiZefEyAzqMnYKjv"
        onChange={setRecaptcha}
      />
      {error ? <Alert severity="error">{error}</Alert> : <></>}
      {renderSendButton()}
      <p>Mais informações em <a href="mailto:school@catalisemovement.com">school@catalisemovement.com<BiLinkExternal/></a></p>
    </SendForm> 
  </FormSlide>;
});
export default FormPage;