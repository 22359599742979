import * as React from 'react';
import { useRef } from 'react';
import { FullPage, Slide } from 'react-full-page';

import './App.css';
import FormPage from './Pages/FormPage';
import LogoPage from './Pages/LogoPage';
import VideoPage from './Pages/VideoPage';
import XTPage from './Pages/XTPage';
import './Reset.css';

function App() {
  const FullPageRef = useRef();
  const VideoPageRef = useRef();
  const XTPageRef = useRef();
  const LogoPageRef = useRef();

  const handleExitSlide = () => {
    LogoPageRef.current.onExitSlide();
    XTPageRef.current.onExitSlide();
    VideoPageRef.current.onExitSlide();
  }
  const handleAfterChangeSlide = (slide) => {
    handleExitSlide();
    switch(slide.to)
    {
      case 0:
        LogoPageRef.current.onEnterSlide();
        break;
      case 1:
        XTPageRef.current.onEnterSlide();
        break;
      case 2:
        VideoPageRef.current.onEnterSlide();
        break;
      default:
        break;
    };
  }
  const handleScrollToSlide = (index) => {
    FullPageRef.current.scrollToSlide(index)
  };

  return (
    <FullPage ref={FullPageRef} afterChange={handleAfterChangeSlide}>
      <Slide>
        <LogoPage ref={LogoPageRef} scrollToSlide={handleScrollToSlide}/>
      </Slide>
      <Slide>
        <XTPage ref={XTPageRef} scrollToSlide={handleScrollToSlide}/>
      </Slide>
      <Slide>
        <VideoPage ref={VideoPageRef} scrollToSlide={handleScrollToSlide}/>
      </Slide>
    </FullPage>
  );
}

export default App;
