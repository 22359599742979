import styled from 'styled-components';
import MainSlide from '../../Components/mainSlide';
import {BlackAboutButton} from '../../Components/aboutButton.js';
import { BsArrowDownShort } from 'react-icons/bs';
import { useRef } from 'react';
import { forwardRef, useImperativeHandle } from "react"

const YouTube = styled.iframe`
width: 60vw;
height: 30vw;
opacity: 0;
transition: opacity ease-in-out 1s, transform ease-in-out 1s;
@media (max-width: 768px) {
  width: 80vw;
  height: 80vw;
}
`;
const VideoSlide = styled(MainSlide)`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
background: #000;
`;

const VideoPage = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        onEnterSlide() {
            YouTubeRef.current.style.opacity = 1;
            YouTubeRef.current.style.transform = "";
        },
        onExitSlide() {
            YouTubeRef.current.style.opacity = 0;
            YouTubeRef.current.style.transform = "translateY(-15px)";
        }
    }))
    const YouTubeRef = useRef();

    return <VideoSlide>
    <YouTube ref={YouTubeRef} src="https://www.youtube.com/embed/9_f1dpZAlss" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></YouTube>
  </VideoSlide>;
});
export default VideoPage;