import styled from 'styled-components';

const MainSlide = styled.div`
font-family: 'Roboto', sans-serif;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
background: #000;
background-position: left bottom;
background-size: contain;
background-repeat: no-repeat;

h1 {
  margin-bottom: 50px;
  text-align: center;
  font-size: 100px;
  font-weight: 600;
  color: #f4ed37;
}
@media (max-width: 768px) {
  h1 {
    font-size: 50px;
  }
}
`;
export default MainSlide;