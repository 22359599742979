import styled from 'styled-components';
import verdadeLogo from '../../Assets/verdadeLogo.png';
import cataliseXtLogo from '../../Assets/cataliseXtLogo.png';
import pics from '../../Assets/PICS.png';
import dtBackground from '../../Assets/DT.png';
import AboutButton from '../../Components/aboutButton';
import MainSlide from '../../Components/mainSlide';
import { BsArrowDownShort } from 'react-icons/bs';
import { forwardRef, useImperativeHandle } from "react"

const Logo = styled.img`
  max-width: 800px;
  width: 80%;
  opacity: 0;
  transform: scale(1);
  transition: opacity ease-in-out 2s, transform ease-out 1s;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

const VerdadeLogo = styled(Logo)``;
const ImageLink = styled.a`
  transition: opacity ease .2s;
  img {
    width: 100%;
  }
  &:hover {
    opacity: .6;
  } 
`;
const XTSlide = styled(MainSlide)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(${dtBackground});
  background-size: cover;

  ${ImageLink} {
    width: 600px;
    max-width: 80%;
  }
  ${Logo} {
    width: 150px;
    position: absolute;
    bottom: 100px;
    opacity: 1;
  }
  ${VerdadeLogo} {
    bottom: none;
    top: 30px;
    left: 30px;
  }
`;

const XTPage = forwardRef((props, ref) => {
  
    useImperativeHandle(ref, () => ({
      onEnterSlide() {},
      onExitSlide() {}
    })) 
    return <XTSlide>
      <VerdadeLogo src={verdadeLogo}/>
      <ImageLink href="http://verdadeigreja.com">
        <img src={pics} alt="Convidados"/>
      </ImageLink>
      <Logo src={cataliseXtLogo}/>
      <AboutButton onClick={() => props.scrollToSlide(2)}>
        Sobre o Catalise Movement
        <BsArrowDownShort size={25}/>
      </AboutButton>
    </XTSlide>;
});
export default XTPage;