import styled, {keyframes} from 'styled-components';


const arrowAnimation = keyframes`
 0% { bottom: -10px; }
 50% { bottom: -15px; }
 100% { bottom: -10px; }
`
const AboutButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  text-decoration: none;
  outline: none;
  border: none;
  padding: 20px;
  color: #fff;
  position: absolute;
  bottom: 30px;
  cursor: pointer;

  svg {
    position: absolute;
    bottom: -10px;
    animation-name: ${arrowAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`;
const BlackAboutButton = styled(AboutButton)`
  color: #819ba6;
`;
export default AboutButton;
export {BlackAboutButton};